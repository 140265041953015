<ng-container *ngrxLet="value$ as currentValue">
  <mp-autocomplete-input
    #supplierSelector
    [selectedValue]="currentValue"
    [disabled]="disabled"
    [autocompletePanel]="autocomplete.autocompletePanel"
    [clearable]="true"
    autocompleteInputMode="chip-item"
    [inline]="inline"
    [label]="label"
    [placeholder]="placeholder"
    [autocompleteIcon]="icon"
    [chipItemTemplate]="chipItemTemplate.template"
    (searchTermChange)="autocomplete.filterBy($event)"
    (selectedValueChange)="updateValue($event)"
  >
    <ng-template
      #chipItemTemplate="mpTypedTemplate"
      mpTypedTemplate
      [contextType]="supplierSelector.chipItemTemplateContextType"
      let-currentValue
    >
      @if (currentValue.externalNumber) {
        <span class="supplier-number">{{ currentValue.externalNumber }}</span>
      }
      <span class="supplier-name truncate">{{ currentValue.name }}</span>
    </ng-template>
  </mp-autocomplete-input>
</ng-container>

<mp-entity-autocomplete
  #autocomplete="mpEntityAutocomplete"
  panelClass="{{ class }}__autocomplete-panel"
  autoActiveFirstOption
  [optionsFetcher]="optionsFetcher"
  [optionTemplate]="supplierOptionTemplate.template"
>
  <ng-template
    #supplierOptionTemplate="mpTypedTemplate"
    mpTypedTemplate
    [contextType]="autocomplete.optionTemplateContextType"
    let-option
    let-isActive="isActive"
  >
    <div class="supplier-item" [ngClass]="{ 'supplier-item--active': isActive }">
      <div class="supplier-item-icon">
        <mpsm-supplier-icon [supplier]="option.value" />
      </div>

      <div class="supplier-item-text">
        <h4>{{ option.label }}</h4>

        @if (option.subLabel; as optionSubLabel) {
          <span>{{ optionSubLabel }}</span>
        }
      </div>
    </div>
  </ng-template>
</mp-entity-autocomplete>
