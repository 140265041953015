import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { IndustryCatalogType } from '@core/shared/domain';
import { CatalogTypeIconComponent } from '@core/ui';
import { PlatformSupplierBasic } from '@mp/supplier-management/common/domain';

import { SupplierAutocompleteInputComponent } from '../supplier-autocomplete-input/supplier-autocomplete-input.component';

@Component({
  selector: 'mpsm-catalog-supplier-selector',
  standalone: true,
  templateUrl: './catalog-supplier-selector.component.html',
  styleUrls: ['./catalog-supplier-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SupplierAutocompleteInputComponent, CatalogTypeIconComponent],
})
export class CatalogSupplierSelectorComponent {
  @HostBinding() readonly class = 'mpsm-catalog-supplier-selector';

  @Input() lieferant?: PlatformSupplierBasic;
  @Input() editMode = false;
  @Input() disabled = false;
  @Input() katalogtyp?: IndustryCatalogType;
  @Input() label = 'Plattformlieferanten zuordnen';
  @Input() placeholder = 'Plattformlieferanten zuordnen';
  @Input() icon = 'local_shipping';

  @Output() readonly lieferantChanges = new EventEmitter<PlatformSupplierBasic | undefined>();

  onLieferantChanges(lieferant?: PlatformSupplierBasic): void {
    this.lieferantChanges.next(lieferant);
  }
}
