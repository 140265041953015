import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { LetDirective } from '@ngrx/component';
import { BehaviorSubject, Observable, map } from 'rxjs';

import { TypedTemplateDirective } from '@core/shared/util';
import {
  AutocompleteInputComponent,
  AutocompleteOptionsFetcher,
  EntityAutocompleteComponent,
  SelectOption,
} from '@core/ui';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PlatformSupplierBasic, supplierCategoryDescriptions } from '@mp/supplier-management/common/domain';
// TODO: Fix this import -> move to common
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PlatformSupplierService } from '@mp/supplier-management/platform-suppliers/data-access';

import { SupplierIconComponent } from '../supplier-icon/supplier-icon.component';

@Component({
  selector: 'mpsm-supplier-autocomplete-input',
  standalone: true,
  templateUrl: './supplier-autocomplete-input.component.html',
  styleUrl: './supplier-autocomplete-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    LetDirective,
    ReactiveFormsModule,

    EntityAutocompleteComponent,
    SupplierIconComponent,
    AutocompleteInputComponent,
    TypedTemplateDirective,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SupplierAutocompleteInputComponent,
    },
  ],
})
export class SupplierAutocompleteInputComponent implements ControlValueAccessor {
  @HostBinding() readonly class = 'mpsm-supplier-autocomplete-input';

  @ViewChild(EntityAutocompleteComponent, { static: true })
  autocomplete!: EntityAutocompleteComponent;

  @Input() appearance?: string;

  @Input() inline = false;

  @Input() label = 'Übergeordneter Lieferant';
  @Input() placeholder = 'Plattformlieferanten zuordnen';
  @Input() icon = 'local_shipping';

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this.control[this._disabled ? 'disable' : 'enable']();
  }

  private _disabled = false;

  _value: PlatformSupplierBasic | undefined;
  private readonly _value$: BehaviorSubject<PlatformSupplierBasic | undefined> = new BehaviorSubject(
    undefined as PlatformSupplierBasic | undefined,
  );
  readonly value$: Observable<PlatformSupplierBasic | undefined> = this._value$.asObservable();

  @Input()
  get value() {
    return this._value;
  }

  set value(newValue: PlatformSupplierBasic | undefined) {
    this._value = newValue;
    this._value$.next(newValue);
  }

  @Output() readonly valueChanges = new EventEmitter<PlatformSupplierBasic>();

  touched = false;
  readonly control = new UntypedFormControl('');

  readonly optionsFetcher: AutocompleteOptionsFetcher<PlatformSupplierBasic>;

  onTouched: () => void = () => {};
  onChange: (value: PlatformSupplierBasic | undefined) => void = () => {};

  constructor(service: PlatformSupplierService) {
    this.optionsFetcher = this.buildOptionsFetcher(service);
  }

  private buildOptionsFetcher(service: PlatformSupplierService): AutocompleteOptionsFetcher<PlatformSupplierBasic> {
    return (searchTerm: string | undefined) =>
      service.getAll({ searchTerm, pageSize: 25 }).pipe(map(({ data }) => this.mapSuppliersToSelectOptions(data)));
  }

  private mapSuppliersToSelectOptions(suppliers: PlatformSupplierBasic[]): SelectOption<PlatformSupplierBasic>[] {
    return suppliers.map((supplier) => ({
      label: supplier.name,
      subLabel: this.getSupplierOptionSubLabel(supplier),
      value: supplier,
    }));
  }

  private getSupplierOptionSubLabel({ category, externalNumber }: PlatformSupplierBasic): string | undefined {
    if (category && externalNumber) {
      return `${supplierCategoryDescriptions[category]} ${externalNumber}`;
    }
    if (category) {
      return supplierCategoryDescriptions[category];
    }
    if (externalNumber) {
      return externalNumber;
    }
    return undefined;
  }

  updateValue(value: PlatformSupplierBasic | undefined): void {
    if (!this.disabled) {
      this.writeValue(value);
      this.onChange(value);
      this.markAsTouched();
    }
  }

  /* ControlValueAccessor Methods */

  writeValue(value: PlatformSupplierBasic | undefined): void {
    this._value = value;
    this._value$.next(value);
    this.valueChanges.emit(value);
  }

  registerOnChange(fn: (value: PlatformSupplierBasic | undefined) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this._disabled = disabled;
    disabled ? this.control.disable() : this.control.enable();
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
